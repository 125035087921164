/**
 * Support page
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import Accordionmember from "../components/accordionmember"
import Bannerwithcta from "../components/bannerwithcta"
import Contactinfos from "../components/contactinfos"
import Herotype2 from "../components/herotype2"
import Hubspot from "../components/hubspotform"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Titledecorative from "../components/titledecorative"
import "../styles/pages/support.scss"

/* Page function declaration */
const Supportpage = ({data}) => {

  //Const data page
  const dataPage = data.wpPage
  const dataAcf = dataPage.acfPageSupport


  //Array team member
  // let arrTeamMember = []
  // dataAcf.repeaterTeamMember.map((member, index) => (
  //   arrTeamMember.push({index: index, name: member.nameTeamMember, title: member.titleTeamMember, arrbio: member.repeaterBioTeamMember, picture: member.pictureTeamMember })
  // ))

  //Const data Options
  const dataOptionsbanner = data.options.acfPageOptions
  var arrOptionsbanner = []
  arrOptionsbanner = dataOptionsbanner.repeaterBannerCta.filter(function(element){
    return element.topicBannerCta === "getstarted2"
  })

  return (
    <Layout>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
      />

      {/* Hero section */}
      <section className="section-hero-support top-section">
        <Herotype2 h1title={dataAcf.titleHeroSupport} textIntro={dataAcf.textHeroSupport} colorBar="orange" />
      </section>

      {/*support details section */}
      <section className="section-details-support">
        <div className="wrap-intro-details-support wrap-maincontent">
          <div className="box-info">
            <Titledecorative title={dataAcf.titleInformationSupport} colorBar="orange" />
          </div>
          <div className="box-info">
            <Contactinfos classNameInfos="support" />
          </div>
        </div>
      </section>

      {/*support request form section */}
      <section className="section-request-support">
        <div className="wrap-maincontent wrap-form">
          <h2><span>{dataAcf.titleRequestSupport}</span></h2>
          <div className="text-form" dangerouslySetInnerHTML={{ __html:dataAcf.textRequestSupport}} />
          <Hubspot formIdentifier={dataAcf.formIdHubspotSupport} />
        </div>
      </section>

      {/*Suppor team section */}
      <section className="section-team-support">
        {/* <div className="wrap-team wrap-maincontent">
          <Titledecorative title={dataAcf.titleSupportTeam} colorBar="orange" />
          <div className="flex-box">
            <div className="intro first-body" dangerouslySetInnerHTML={{ __html:dataAcf.textSupportTeam}} />
          </div>
          <div className="box-teammenber">
            <Accordionmember arrItems={arrTeamMember} />
          </div>
        </div> */}

      </section>

      {/* Banner CTA Suppot */}
      <Bannerwithcta
        classBanner="section-banner-support"
        titleBanner={arrOptionsbanner[0].titleBannerCta}
        textBanner={arrOptionsbanner[0].textBannerCta}
        ctaLabelBanner={arrOptionsbanner[0].ctaLabelBanner}
        ctaLinkBanner={arrOptionsbanner[0].ctaLinkBanner}
      />
    </Layout>
  )
}

/* Query page */
export const query = graphql`
  query {
    wpPage(slug: {eq: "support"}) {
      acfPageSupport {
        fieldGroupName
        formIdHubspotSupport
        
        textHeroSupport
        textRequestSupport
        textSupportTeam
        titleHeroSupport
        titleInformationSupport
        titleRequestSupport
        titleSupportTeam
      }
      title
      seo {
        title
        metaDesc
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterBannerCta {
          ctaLabelBanner
          ctaLinkBanner
          textBannerCta
          titleBannerCta
          topicBannerCta
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     wpPage(slug: {eq: "support"}) {
//       acfPageSupport {
//         fieldGroupName
//         formIdHubspotSupport
//         repeaterTeamMember {
//           nameTeamMember
//           pictureTeamMember {
//             localFile {
//               childImageSharp {
//                 gatsbyImageData (layout: CONSTRAINED, width: 200)
//               }
//             }
//           }
//           repeaterBioTeamMember {
//             itemBioMember
//           }
//           titleTeamMember
//         }
//         textHeroSupport
//         textRequestSupport
//         textSupportTeam
//         titleHeroSupport
//         titleInformationSupport
//         titleRequestSupport
//         titleSupportTeam
//       }
//       title
//       seo {
//         title
//         metaDesc
//       }
//     }
//     options: wpPage(slug: {eq: "options-website"}) {
//       acfPageOptions {
//         repeaterBannerCta {
//           ctaLabelBanner
//           ctaLinkBanner
//           textBannerCta
//           titleBannerCta
//           topicBannerCta
//         }
//       }
//     }
//   }
// `

/* Export page informations */
export default Supportpage
